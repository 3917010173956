<template>
  <div class="view view4">
    <Content :view="$store.state.config.views[4]" />
  </div>
</template>

<script>

import Content from '@/components/Content.vue'

export default {
  name: 'View4',
  components: {
    Content
  },
  methods: {
    goToLogin () {
      this.$router.push('/')
    }
  }
}
</script>
